import React, {Component} from 'react';
import BlogTemplate from './../BlogTemplate'
import CoverImage from './../../assets/single-elements/blog-images/photo-exukonline-experience.jpeg'
import {Helmet} from "react-helmet";



class TheExperience extends Component {

  render() {
    const title = "The Zed Auto Experience"
    const bImage = CoverImage
    const subHeading = "CHALLENGES OF SHOPPING FOR A CAR LOCALLY."
    const paragraph1 = "It can be very stressful for an individual that is looking to shop for a car locally. \n" +
      "\n" +
      "First there are the generic Zambia used car groups, filled with “car guys” selling cars, that take pride in adding crazy markups despite the condition of the car. These are the guys found mostly on social media. This is where you find the same car listed by more than one vendor with different prices. This makes it hard for you, the would-be buyer, to know who is the rightful owner of the vehicle, and also what the rightful asking price is.\n" +
      "\n" +
      "Then there is the biggest risk which happens to be every car shopper’s fear, the SCAMMERS!  We have seen how the number of scammers has skyrocketed in the recent past. A good number of individuals have come on board posing as car dealers with vehicles to sell when in fact not. A high number of unsuspecting buyers have been scammed off their hard earned money to such individuals. \n" +
      "\n" +
      "Sometimes, you have looked through social media and found your desired car, that is in a reasonable price range. It’s “very neat”, “pressure free”,  You message the seller and they only go online a day after, the agony of having to wait such a long time for a response can be frustrating.\n" +
      "\n" +
      "The general lack of organized car online car listing options makes the shopping even more cumbersome. It is extremely difficult to find exactly what you are looking for. \n"
    const subHeading2 = "Zed Autos Offer"
    const paragraph2 = "Given the many challenges above, zedautos.com gives you the option  to search for vehicles and compare prices. The site also provides detailed descriptions of a car's features, rightful seller contact details. All this, done from your phone or laptop at any time that is convenient for you. \n" +
      "\n" +
      "The security measures that have been put in place when listing a vehicle will help you, the buyer minimize the risk of being scammed. And Because all listings are systematically organized, you are able to find the brand and model of car that you are looking for,  with so much ease. \n" +
      "\n" +
      "Best of all, you do not  have to deal with  the ‘middleman’ that has placed an exorbitant markup to break your bank.\n" +
      "\n" +
      "Enjoy your shopping!! \n" +
      "\n"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zed Autos</title>
          <meta name="description"
                content="Zed Autos - Buy with confidence" />
        </Helmet>
        <BlogTemplate
          title={title}
          bImage={bImage}
          subHeading={subHeading}
          paragraph1={paragraph1}
          subHeading2={subHeading2}
          paragraph2={paragraph2}>
        </BlogTemplate>
      </div>
    )
  }

}

export default TheExperience;
