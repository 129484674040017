import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, NavLink, Col, Input, FormGroup, InputGroup, InputGroupText, InputGroupAddon,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { filterAction } from "../store/actions/filterCollapseAction";
import logo from "../assets/images/logo.png"
import { authAction, vehicleAction } from "../store/actions";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

const links = [
  { href: '/', text: 'Home'},
  { href: '/', text: 'Cars' },
  { href: '/auto-parts', text: 'Parts' },
  { href: '/about-us', text: 'About' }
];

const createNavItem = ({ href, text, className='my-nav-link' }) => (
  <NavItem>
    <NavLink href={href} className={className}>{text}</NavLink>
  </NavItem>
);

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    const { cookies } = props;

    this.state = {
      isOpen: false,
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      redirect: false,
      searchInput: "",
      allProductList: [],
      isSidePaneOpen: false,
      dropdownOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }
  }

  toggle() {
    this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggleNav();
    }
  }

  handleSignOut = async event => {
  const { cookies } = this.props;
  const token = localStorage.getItem('token')
  event.preventDefault();
  if (this.props.apiTokenLogout) {
    await this.props.apiTokenLogout({
      cookies,
      sessionToken: {
        token: token
      }
  });

  this.setState({
    userLoggedIn: false,
    email: "",
    redirect: true
  })

  this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  this.props.history.push('/')
  }
  }


  render() {
    const { isLoggedIn } = this.props.authReducer

    // var location = window.location.pathname;
    return (
      <div>
        <Navbar style={{ backgroundColor: "#2338dc"}} expand="md">
          <NavbarBrand style={{ color: "#ffffff"}} href="/" className="logo-right">zedautos</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav  style={{ color: "#ffffff"}} className="ml-auto" navbar>
              {links.map(createNavItem)}
               <NavItem>
                 {!isLoggedIn && (
                   <NavLink
                     href="/login"
                     onClick={this.closeNavbar}
                     style={{ color: "#ffffff"}}
                     >
                      Log in
                   </NavLink>
                 )}
                 {isLoggedIn && (
                   <NavLink
                     href="/"
                     onClick={this.handleSignOut}
                     style={{ color: "#ffffff"}}
                   >
                     Log out
                   </NavLink>
                   // <Link
                   // to="/"
                   // onClick={this.handleSignOut}
                   // className="fancy">
                   // <span className="fancy-header-items"> Hi, Sign Out</span>
                   // </Link>
                 )}
               </NavItem>
               <NavItem className="my-account">
                 <Link
                   to="/profile"
                   className="fancy fancy-icons">
                   <ManageAccountsRoundedIcon style={{ color: 'white' }}/>
                 </Link>{" "}
               </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      // <div className="header-section">
      //   <div className="header-container">
      //     <span/>
      //     <div className="header-left">
      //       {/*<span className="logo-left" style={{fontWeight: '500'}}>exUK</span>*/}
      //     </div>
      //     <div className="header-right">
      //       <Navbar dark expand="lg" id="" className="upper-nav">
      //         <Nav
      //           className="ml-auto"
      //           style={{ display: "inline-block" }}
      //           navbar>
      //           <NavItem>
      //             <a href="https://www.youtube.com/c/jamesqquick">
      //               Item here
      //               <img
      //                 src={require("../assets/single-elements/youtube-32.png")}
      //                 alt="social-icons"
      //                 className="social-icons"
      //               />
      //             </a>
      //           </NavItem>
      //           <NavItem>
      //             <a href="https://facebook.com/">
      //               And here
      //               <img
      //                 src={require("../assets/single-elements/facebook-32.png")}
      //                 alt="social-icons"
      //                 className="social-icons"
      //               />
      //             </a>
      //           </NavItem>
      //           <NavItem>
      //             <a href="https://twitter.com/login?lang=e">
      //               <img
      //                 src={require("../assets/single-elements/twitter-32.png")}
      //                 alt="social-icons"
      //                 className="social-icons"
      //               />
      //             </a>
      //           </NavItem>
      //           <NavItem style={{ padding: "0px" }}>
      //             <a href="https://www.instagram.com/">
      //               <img
      //                 className="social-icons"
      //                 src={require("../assets/single-elements/instagram.png")}
      //                 alt="social-icons"
      //               />
      //             </a>
      //           </NavItem>
      //         </Nav>
      //       </Navbar>
      //       <Navbar dark expand="lg" id="navbar-first-row">
      //         <NavbarBrand href="/" className="mr-auto" style={{paddingLeft: '16px'}}>
      //           {/*<span className="logo-right">Zed<span style={{color: "black"}}>Autos</span></span>*/}
      //           <Row>
      //             <Col>
      //               <a href="/" className="logo">
      //                 <img style={{maxWidth: '225px'}}
      //                      src={logo}
      //                 >
      //
      //                 </img>
      //               </a>
      //             </Col>
      //             <Col>
      //
      //             </Col>
      //           </Row>
      //
      //
      //         </NavbarBrand>
      //         <NavbarToggler onClick={this.toggle} />
      //         <Nav className="ml-auto" id="navbar-nav" navbar>
      //           <NavItem>
      //             <Link
      //             to="/my-basket">
      //              <img
      //               src={require("../assets/single-elements/cart-73-32.png")}
      //               className="cart"
      //               alt="cart"
      //             />
      //             </Link>
      //
      //           </NavItem>
      //           <div>&nbsp;</div>
      //           <NavItem>
      //             {!isLoggedIn && (
      //               <Link
      //                 to="/login"
      //                 onClick={this.closeNavbar}
      //                 className="fancy">
      //                 <span className="fancy-header-items"> Hello, Sign in </span>
      //               </Link>
      //             )}
      //             {isLoggedIn && (
      //               <Link
      //               to="/"
      //               onClick={this.handleSignOut}
      //               className="fancy">
      //               <span className="fancy-header-items"> Hi, Sign Out</span>
      //               </Link>
      //             )}
      //           </NavItem>
      //           <div>&nbsp;</div>
      //           <NavItem className="my-account">
      //             <Link
      //               to="/profile"
      //               className="fancy fancy-icons">
      //               <ManageAccountsOutlinedIcon/>
      //             </Link>{" "}
      //           </NavItem>
      //           <NavItem >
      //             <Link
      //               to="/my-basket"
      //               className="fancy fancy-icons">
      //               <ShoppingCartOutlinedIcon/>
      //             </Link>
      //           </NavItem>
      //         </Nav>
      //       </Navbar>
      //     </div>
      //
      //   </div>
      //
      //   <div className="nav-container">
      //     <Navbar dark expand="lg" id="navbar-second-row">
      //       <Collapse isOpen={this.state.isOpen} navbar>
      //         <Nav className="" navbar>
      //
      //           <Link
      //             to="/"
      //             className="mr-3-first-link"
      //             onClick={this.closeNavbar}>
      //             <NavItem className="first-nav-item">
      //               <img
      //                 className="nav-icon"
      //                 src={require("../assets/single-elements/icons8-home-26.png")}
      //                 alt="icons"
      //               />
      //             </NavItem>
      //             Home
      //           </Link>
      //
      //
      //           <Link
      //             to="/electronics"
      //             className="mr-3"
      //             onClick={this.closeNavbar}>
      //             <NavItem>
      //               <img
      //                 className="nav-icon"
      //                 src={require("../assets/single-elements/icons8-car-26.png")}
      //                 alt="icons"
      //               />
      //             </NavItem>
      //             Cars
      //           </Link>
      //
      //           <Link
      //             to="/electronics"
      //             className="mr-3"
      //             onClick={this.closeNavbar}>
      //             <NavItem>
      //               <img
      //                 className="nav-icon"
      //                 src={require("../assets/single-elements/icons8-mobile-26.png")}
      //                 alt="icons"
      //               />
      //             </NavItem>
      //             Commercials
      //           </Link>
      //
      //           <Link
      //             to="/about-us"
      //             className="mr-3"
      //             onClick={this.closeNavbar}>
      //             <NavItem className="nav-item-no-padding">
      //               <img
      //               className="nav-icon"
      //               src={require("../assets/single-elements/icons8-about-26.png")}
      //               alt="icons"
      //               />
      //             </NavItem>
      //             About
      //           </Link>
      //
      //           {!isLoggedIn && (
      //           <Link
      //             to="/login"
      //             onClick={this.closeNavbar}
      //             >
      //             <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
      //               Sign in
      //             </NavItem>
      //           </Link>
      //           )}
      //           {isLoggedIn && (
      //           <Link
      //             to="/"
      //             onClick={this.handleSignOut}
      //             >
      //             <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
      //               Sign Out
      //             </NavItem>
      //           </Link>
      //           )}
      //
      //           <Link
      //             to="/profile"
      //             onClick={this.closeNavbar}
      //           >
      //             <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
      //               My Account
      //             </NavItem>
      //           </Link>
      //
      //           <Link
      //             to="/my-basket"
      //             onClick={this.closeNavbar}
      //           >
      //             <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
      //             Cart
      //             </NavItem>
      //           </Link>
      //
      //
      //         </Nav>
      //       </Collapse>
      //     </Navbar>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = state => ({
  filterReducer: state.filterReducer,
  sideMenuReducer: state.sideMenuReducer,
  authReducer: state.authReducer,

});

const mapDispatchToProps = dispatch => {
  return {
    filterCollapseAction: payload =>
      dispatch(filterAction.setFilterCollapse(payload)),
    apiTokenLogout: payload =>
      dispatch(authAction.apiTokenLogout(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
    withCookies,
    withRouter
)(Header));

