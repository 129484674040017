import React, { useState, useEffect } from "react";
import {Col, Row} from "reactstrap";
import Button from '@mui/material/Button';
import ImageGallery from "react-image-gallery";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";


const PartsDetailComponent = ({
                                  images,
                                  price,
                                  prodTitle,
                                  subtitle,
                                  partNumber,
                                  condition,
                                  description,
                                  contact,
                                  location
                                }) => {
  const [revealNumber, setRevealNumber] = React.useState(false);

  return (
    <div>
      <Paper elevation={2} style={{padding: '16px 0px', margin: '40px 0px 80px 0px'}}>
        <Row>
          <Col className="preview-boxes" xs={12} sm={12} md={7} lg={7}>
            <div>
              <ImageGallery
                items={images}
                showPlayButton={false}
              />
            </div>
          </Col>
          <Col className="preview-boxes" xs={12} sm={12} md={5} lg={5}>
            <h5 className="page-header-content">{prodTitle}</h5>
            <h4 className="page-header-content" style={{color: '#0055d4'}}>
              <NumberFormat
                value={price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'ZMW'} />
            </h4>
            <div style={{color: "#878787"}}>
              {subtitle}
            </div>
            <div style={{color: '#0055d4', paddingTop: '16px'}}>
              {location}
            </div>
            <div>
              <h5
                className="page-header-content"
                style={{paddingTop: "32px"}}
              >
                Specification
              </h5>

              <Row>
                <Col className="table-heading" xs="6">
                  Part number
                </Col>
                <Col className="table-content" xs="auto">
                  {partNumber}
                </Col>
              </Row>
              <Row>
                <Col className="table-heading" xs="6">
                  Condition
                </Col>
                <Col className="table-content" xs="auto">
                  {condition}
                </Col>
              </Row>
              <h5
                className="page-header-content"
                style={{paddingTop: "32px"}}
              >
                Buy
              </h5>
              <Row>
                <Col xs="6">
                  <i className="fas fa-phone spec-icons"></i>
                </Col>
                {revealNumber ?
                  <Col xs="auto" style={{fontWeight: 800}}>
                    {contact}
                  </Col>
                  :
                  <Col xs="auto">
                    <Button variant="text"
                            onClick={() => { setRevealNumber(true); }}
                    >
                      Reveal phone number
                    </Button>
                  </Col>
                }
              </Row>
            </div>
            <hr style={{borderTop: "1px solid #00000040"}}/>
            <div>
              <h6 className="page-header-content">Seller's description</h6>
              <div style={{minHeight: '80px', whiteSpace: 'pre-wrap'}}>
                {description}
              </div>
            </div>
          </Col>
        </Row>
      </Paper>
    </div>
  )
};

export default PartsDetailComponent;