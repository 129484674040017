import React, { Component } from 'react';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { connect } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import persistor from './PersistStore'
// import MessengerCustomerChat from 'react-messenger-customer-chat'
import store from "./store/store";
import './App.css';
import {vehicleAction} from "./store/actions";
import {initGA} from "./components/Tracking"
import Fonts from "./Fonts"
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Lato',
      textTransform: 'none',
      // fontSize: 16,
    },
  },
});

class App extends Component {
  componentDidMount() {
    Fonts()
    let payload = {parts: "false"}
    this.props.getShippingMethods()
    // this.props.getProductCategories(payload)
    initGA('UA-153402610-5')
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <div>
                <Main />
                {/*<MessengerCustomerChat*/}
                {/*  pageId="102070134610567"*/}
                {/*  appId="242468800428892"*/}
                {/*/>*/}
              </div>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  getShippingMethodsSuccess: state.getShippingMethodsSuccess,
  getProductCategoriesSuccess: state.getProductCategoriesSuccess
})

const mapDispatchToProps = dispatch => {
  return {
    getShippingMethods: payload =>
      dispatch(vehicleAction.getShippingMethods(payload)),
    getProductCategories: payload =>
      dispatch(vehicleAction.getProductCategories(payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(App);

