import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zed Autos - About Us</title>
          <link rel="canonical" href="https://www.exukonline.com/about-us" />
          <meta name="description"
                content="UK Premier Import Service - A Global e-commerce company" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content"> About Us</h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                            <p className="page-information" style={{fontSize: '16px', paddingTop: '0px'}}>
                              The Zambian automotive market is complex and often inefficient. There are multiple participants
                              and unsurprisingly consumers can find the process of buying or selling a car overwhelming.

                              Through Zed Autos products, services and partnerships, we aim to significantly improve
                              the car buying experience, as well as leverage our existing relationships to improve
                              more of the value chain.
                            </p>
                            <p className="page-information" style={{fontSize: '16px'}}>
                              We are also a highly trusted provider of auto parts in the country.
                              Our highly professional and expert team helps you to procure a wide variety of
                              automobile parts at highly affordable rates.</p>

                          <p className="info-graphics-heading" style={{fontSize: '16px'}} >
                            Connect with us to discuss your requirements!</p>
                          <p className="info-graphics-heading" style={{fontSize: '16px'}} >
                            Call us on +44 7438 767532
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
